.form {
  /* padding: 5em; */
  max-width: 45rem;
  width: 45em;
  /* max-width: 90%; */
  /* z-index: 1;
  animation: showSignInForm 1s; */
  text-align: center;
}

.container {
  max-width: 45rem !important;
  width: 45em;
}

.form button {
  width: 10rem;
}

.forms_link {
  display: inline !important;
  text-align: center;
  padding: 2px;
}

.footer {
  position: absolute;
  bottom: 0;
  height: 60px;
}

.text_block {
  margin-top: 3rem;
  max-width: 45rem;
  min-width: 5rem;
  text-align: center;
  padding: 2px;
}

.form_select_group {
  width: 50%;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.form_line {
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 3em;
  margin-top: 2em;
}
