.footer {
  /* position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: #13151a;
  padding: 25px 0px 22px 0px;
  font-size: 15px;
  padding: 1.2rem;
  text-align: center;
  border-top: 1px solid #0a0a0a; */
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #13151a;
  font-size: 15px;
}

.footer a {
  color: rgb(185, 191, 196) !important;
  text-decoration: none;
}

.footer a:hover {
  color: rgb(255, 255, 255) !important;
  text-decoration: underline;
}
