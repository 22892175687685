body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(31, 156, 114);
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

/* .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
} */

.wrapper {
  display: flex;
  width: 100%;
  min-width: 0;
  min-height: 100vh;
  /* background: #f5f7fb; */
  flex-direction: column;
  overflow: hidden;
}

/* .wrapper form {
  width: 24rem;
  max-width: 100%;
  z-index: 1;
  animation: showSignInForm 1s;
} */

/* .wrapper a {
  display: block;
  text-align: center;
  padding: 2px;
} */
